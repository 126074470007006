@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/
/* contact  */
.contact {
	color: $white;
	padding: 0;
	width: 100%;
	position: relative;
	background: transparent;
	&::before {
		background: url(../img/bg_03_pc.jpg) 50% top no-repeat;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: -1;
	}
	& .l-cont {
		position: relative;
	}
	&-flex {
		@include media(pc) {
			@include flex-between;
			&__left {
				width: 36%;
				margin-left: 0;
			}
			&__right {
				width: 60%;
				margin-right: 0;
			}
		}
	}
	& .c-box__tit {
		padding-bottom: 0.8rem;
		border-bottom: solid $white 1px;
		margin: 0 auto 1.5rem;
		@include media(pc) {
			@include fz(24);
		}
		@include media(tb_less) {
			text-align: left;
		}
	}
	&__tel {
		margin-bottom: 2rem;
		font-size: calc(2rem + 6 * (100vw - 320px) / 680);
		@include media(pc) {
			@include fz(26);
		}
		a {
			color: $white !important;
			@include dec-none;
			vertical-align: bottom;
		}
	}
}

.privacy-box {
	padding: 1.5rem;
	height: 150px;
	margin: 0 auto 2rem;
	overflow-y: auto;
	border: 1px solid $l-gray;
	@include media(pc_s) {
		padding: 1.5rem 2rem;
		height: 200px;
	}
}

/*	contact_form
---------------------------------------------------------------------*/
.contact__table {
	margin: 0 auto 2rem;
	width: 100%;
	line-height: 1.5;
	@include media(pc_s) {
		display: table;
	}
	& th,
	& td {
		font-size: 1.4rem;
		font-weight: normal;
		width: 100%;
		display: block;
		vertical-align: middle;
		@include media(pc_s) {
			display: table-cell;
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}
	& tr {
		&:not(:last-child) {
			@include media(pc_s) {
				border-bottom: 1px solid $l-gray;
			}
		}
	}
	& th {
		text-align: left;
		color: $white;
		padding-bottom: 0.5rem;
		@include media(pc_s) {
			width: 30%;
		}
		span {
			color: #ff3434;
			padding: 0 0.2rem;
			margin-left: 0.5rem;
		}
	}
	& td {
		padding-bottom: 1.5rem;
		@include media(pc_s) {
			width: 70%;
		}
	}
	& textarea {
		height: 10em;
	}
}

/* form-parts
----------------------------------------------------------------*/
input,
textarea,
select {
	background-color: #f6f7f8;
	font-size: 14px;
	font-size: 1.4rem;
	width: 100%;
	margin: 3px 0;
	padding: 0.5em;
	border: 1px solid $l-gray;
	border-radius: 2px;
}

textarea {
	font-family: sans-serif;
}

input:focus,
textarea:focus {
	background-color: $white;
	/* box-shadow */
	box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

:placeholder-shown {
	color: #ccc;
}

::-webkit-input-placeholder {
	color: #ccc;
}

::-moz-placeholder {
	color: #ccc;
}

:-ms-input-placeholder {
	color: #ccc;
}

input[type="submit"] {
	-webkit-appearance: none;
}

input[class="sub-btn"] {
	display: block;
	width: 160px;
	margin: 0 auto;
    padding: 1.3rem;
	position: relative;
	@include transition;
	text-align: center;
	text-decoration: none;
	letter-spacing: 2px;
	border-radius: 3px;
	color: $white;
	background: $main_c;
	border-color: $main_c;
	font-size: 1.7rem;
	@include f-family(font01);
	&:hover {
		color: $white;
		background: $sub_c;
		border: 1px solid $sub_c;
	}
}

input[class="back-btn"] {
	margin-top: 1rem;
	display: block;
	width: 160px;
	margin: 0 auto;
	padding: 1.3rem;
	position: relative;
	@include transition;
	text-align: center;
	text-decoration: none;
	letter-spacing: 2px;
	border-radius: 3px;
	color: $main_c;
	background: $white;
	border-color: $main_c;
	font-size: 1.7rem;
	&:hover {
		color: $white;
		background: $sub_c;
		border: 1px solid $sub_c;
	}
}

input[type="radio"],
input[type="checkbox"] {
	display: inline;
	width: 20px;
	vertical-align: top;
}

.radio,
.checkbox {
	@include transition;
	position: relative;
	display: inline-block;
	margin: 0 20px 8px 0;
	padding: 12px 12px 12px 42px;
	border-radius: 3px;
	background-color: $blue;
	vertical-align: middle;
	cursor: pointer;
}

.radio:hover,
.checkbox:hover {
	background-color: #a8a8f4;
}

.radio:hover:after,
.checkbox:hover:after {
	border-color: $sub_c;
}

.radio:after,
.checkbox:after {
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	position: absolute;
	top: 50%;
	left: 16px;
	display: block;
	margin-top: -8px;
	width: 16px;
	height: 16px;
	border: 1px solid $main_c;
	border-radius: 6px;
	content: "";
}

.radio:before {
	transition: all 0.3s ease;
	position: absolute;
	top: 50%;
	left: 19px;
	display: block;
	margin-top: -6px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: $blue;
	content: "";
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

input[type="radio"]:checked + .radio:before {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.checkbox:before {
	transition: all 0.3s ease;
	position: absolute;
	top: 50%;
	left: 21px;
	display: block;
	margin-top: -5px;
	width: 5px;
	height: 9px;
	border-right: 3px solid $sub_c;
	border-bottom: 3px solid $sub_c;
	content: "";
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

input[type="checkbox"]:checked + .checkbox:before {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.no-csstransforms input[type="radio"],
.no-csstransforms input[type="checkbox"] {
	display: inline;
}

.no-csstransforms .radio,
.no-csstransforms .checkbox {
	display: none;
}

.no-csstransforms .checkbox:before {
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	position: absolute;
	top: 50%;
	left: 21px;
	display: block;
	margin-top: -5px;
	width: 5px;
	height: 9px;
	border-right: 3px solid $sub_c;
	border-bottom: 3px solid $sub_c;
	content: "";
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

input[type="checkbox"]:checked + .no-csstransforms .checkbox:before {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input[class*="middle"] {
	margin-right: 8px !important;
	margin-right: 0.5rem !important;
}

textarea {
	height: 10em;
}

select {
	width: 40%;
}

.form-short {
	width: 60%;
	margin-right: 0.5rem;
	@include media(tb) {
		width: 40%;
	}
}

.form-middle {
	width: 70%;
	margin-right: 0.5rem;
	@include media(sp_less) {
		width: 100%;
	}
}
