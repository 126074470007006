@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.blog__wrap {
	margin-bottom: 4rem;
	@include clearfix;
	@include media(pc_s) {
		float: left;
		width: calc(96% - 220px);
	}
	@include media(pc) {
		width: 74%;
	}
}

aside {
	border-radius: 3px;
	-webkit-border-radius: 3px;
	border: 1px solid $l-gray;
	padding: 1.5rem;
	@include media(pc_s) {
		width: 220px;
		float: right;
	}
	@include media(pc) {
		width: 22%;
	}
}

.alignright {
	@include media(pc_s) {
		float: right;
		margin: 0 0 1rem 1rem;
	}
}

.alignleft {
	@include media(pc_s) {
		float: left;
		margin: 1rem 1rem 0 0;
	}
}

.aligncenter {
	@include media(pc_s) {
		display: block;
		margin: 0 auto 1rem;
	}
}

.blog-list {
	&__contbox {
		@include flex-column;
		row-gap: clamp(1.5em,3vw,3rem);
	}
	a {
		color: $txt_c;
		width: 100%;
		display: block;
		border: 1px solid $l-gray;
		border-radius: 3px;
		padding: 1.25rem 2rem 2rem;
		@include dec-none;
		height: 100%;
		overflow: hidden;
		@include transition;
		&:hover {
			border-color: $sub_c;
			.blog-list__btn-wrap {
				span {
					background: $sub_c;
					border-color: $sub_c;
				}
			}
		}
	}
	&__tit {
		font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
		font-weight: 500;
		margin-bottom: 1rem;
		@include media(pc) {
			@include fz(16);
		}
	}
	&__flex {
		@include media(pc_s) {
			display: flex;
			justify-content: space-between;
		}
	}
	&__img {
		@include aspect-img;
		@include media(pc_s) {
			width: 30%;
			padding-right: 2%;
		}
		@include media(tb_less) {
			margin-bottom: 0.5rem;
		}
	}
	.txt {
		@include media(pc_s) {
			width: 68%;
		}
	}
	p {
		margin-bottom: 1.5rem;
	}
	& .date {
		text-align: right;
	}

	&__btn-wrap {
		float: right;
		width: 50%;
	}
}
.blog-cat {
	&__btn-wrap {
		margin-top: 2rem;
		@include media(pc_s) {
			float: right;
			width: 40%;
		}
	}
}

.blog-single {
	&__meta {
		width: 100%;
		overflow: hidden;
		@include clearfix;
		display: flex;
		flex-wrap: wrap;
		& .twitter,
		& .fb-like.fb_iframe_widget {
			position: relative;
		}
		& .twitter {
			z-index: 1;
			margin-right: 0.5rem;
		}
		iframe {
			padding: 0 !important;
			border: none !important;
			vertical-align: top;
		}
		& .data {
			text-align: right;
		}
	}
	&__post-thumbnail {
		margin-bottom: 1.6rem;
	}
	& .txt {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		border-bottom: dotted $gray 1px;
		img {
			height: auto;
		}
	}
	&__btn-wrap {
		max-width: 280px;
		margin-top: 4rem;
		@include m-a;
		@include media(pc_s) {
			width: 30%;
		}
	}
}

/* pdf ボタン　*/
.blog-pdf {
	margin-top: 1.5rem;
	a {
		text-decoration: none !important;
	    padding: 1.5rem 1.5rem;
		border: 1px solid $main_c;
		color: $txt_c;
		background: $sub_bgcl;
		padding-left: 4.8rem;
		border-radius: 50px;
		display: block;
		position: relative;
		@include transition;
		&:after {
			content: "";
			background: url(../img/pdf.svg) no-repeat;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 1.5rem;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			background: #425c93;
			color: $white;
			border-color: #425c93;
		}
	}
}

.pdf-link {
	margin-top: 1.25rem;
	line-height: 1;
	a {
		color: $txt_c;
		&:hover {
			p {
				text-decoration: none !important;
			}
		}
	}
	figure {
		display: inline-block;
		max-width: 158px;
		margin-right: 1rem;
		vertical-align: middle;
	}
	p {
		display: inline-block;
		text-decoration: underline;
	}
}


/*-----------------------------------/
	widget
------------------------------------*/

.widget-blog-aside {
	&:not(:last-of-type) {
		margin-bottom: 3rem;
	}
	&__tit {
		color: $txt_c;
		margin-bottom: 1rem;
		background: $p-gray;
		padding: 0.5rem 1rem;
	}
	li {
		list-style-type: none;
		a {
			position: relative;
			padding-left: 2rem;
			display: block;
			@include transition;
			color: $main_c;
			text-decoration: underline;
			&::before {
				content: "●";
				font-size: 0.7em;
				color: $gray;
				position: absolute;
				left: 0;
				top: 2px;
			}
			&:hover {
				color: $sub_c;
				text-decoration: none;
			}
		}
	}
}

/*-----------------------------------/
	calendar
------------------------------------*/
#wp-calendar {
	border-collapse: collapse;
	width: 100%;
	caption {
		color: $txt_c;
	}
	& thead {
		th {
			text-align: center;
			padding: 4px;
			width: auto;
		}
	}
	td {
		text-align: center;
		padding: 2px 4px;
		& a {
			color: $main_c;
			@include transition;
			display: block;
			text-decoration: none;
			&:hover {
				color: $sub_c;
			}
		}
		&.pad {
			color: $main_c;
		}
		&#today {
			a {
				border: 1px solid;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	記事部分
/--------------------------------------------------------------------*/
p.wp-caption-text {
	clear: none !important;
	padding: 5px 0 0 !important;
	font-size: 12px !important;
	text-align: center !important;
	line-height: 2 !important;
}

/*--------------------------------------------------------------------/
	page_next_prev
/--------------------------------------------------------------------*/
.c-page_next_prev {
	margin-top: 2rem;
	padding: 1rem 0;
	text-align: center;
	font-size: 1.6rem;
	color: $l-gray;
	&::after {
		@include clearfix;
	}
	a {
		color: $l-gray;
		text-decoration: none;
		@include transition;
		&:hover {
			color: $sub_c;
		}
	}
	&__inner {
		display: inline-block;
	}
	.works & {
		color: $white;
		a {
			color: $white;
		}
	}
}

.page-numbers {
	padding: 0.3em 0.5em;
	@include transition(0.2);
	span & {
		border: 1px solid;
	}
}

.nav-prev {
	float: left;
	margin-top: 24px;
	margin-top: 1.5rem;
}

.nav-next {
	float: right;
	margin-top: 24px;
	margin-top: 1.5rem;
}
